$mainColor: #131107;
$mainLighterColor: #383838;
$lightColor: #ccc8b2;
$hoverColor: #f8d61a;

.not-visible {
  display: none;
}
.download-invoice {
  display: inline-block;
  padding: 5px;
  border: 1px solid $mainColor;
  margin-top: 10px;
  width: 11rem;
  text-align: center;
}
.download-invoice:hover {
    border: 1px solid $hoverColor;
  }

.order-list-item {
  .email-column-ui {
    display: none;
  }
}

.downloadPage {
  .email-column-ui {
    display: none;
  }
}

.color-red {
  color: red;
}
.suggested-ss {
  margin-bottom: 10px;
}
.suggested-price-ss {
  margin-bottom: 10px;
  font-weight: normal;
}
.sign-in-to-see-prices-ss {
  margin-top: 10px;
  a {
    text-decoration: underline;
  }
}
.product-presentation-ui
{
  .add-to-cart-border-container-ui {
    .stock-ui {
      margin-top: 0 !important;
      font-size: 0.85vw !important;
    }
    .no-on-mobile-ui:hover {
      background-color: transparent !important;
      background-color: initial !important;
      color: inherit !important;
    }
  }
}
